
<wallet-modal-provider @close="selectWalletOpened = !selectWalletOpened" :featured="featured" :container="container" :logo="logo" :dark="dark" :open="selectWalletOpened"
		:wallets="adapter.wallets" @select="onSelect">
	<template>
		<slot>
			<button v-if="!adapter.wallet || disconnected" class="swv-button swv-button-trigger btn btn-primary text-white" @click="(e) => openSelectWallet(e)">
				Select Wallet
			</button>
			<div v-else-if="!publicKeyBase58" @mouseover="openDropdown">
				<wallet-connect-button :connecting="connecting" :wallet="adapter.wallet" @click="doConnect"></wallet-connect-button>
			</div>
			<div v-else class="swv-dropdown">
				<slot name="dropdown-button">
					<button
							@click="openDropdown"
							class="swv-button swv-button-trigger btn btn-primary text-white"
							:style="{ pointerEvents: dropdownOpened ? 'none' : 'auto' }"
							:aria-expanded="dropdownOpened"
							:title="publicKeyBase58"
					>
						<wallet-icon :wallet="adapter.wallet"></wallet-icon>
						<span v-text="publicKeyTrimmed"></span>
					</button>
				</slot>
			</div>
			<slot v-if="dropdownOpened" name="dropdown">
				<ul
						aria-label="dropdown-list"
						class="swv-dropdown-list"
						:class="{ 'swv-dropdown-list-active': dropdownOpened }"
						ref="dropdownPanel"
						role="menu"
				>
					<slot name="dropdown-list">
						<li v-if="canCopy" @click="(e) => {copyAddress(e); closeDropdown(e);}" class="swv-dropdown-list-item" role="menuitem">
							{{ addressCopied ? "Copied" : "Copy address" }}
						</li>
						<li @click="(e) => {openSelectWallet(e); closeDropdown(e);}" class="swv-dropdown-list-item" role="menuitem">
							Change wallet
						</li>
						<li @click="(e) => {onDisconnect(e); closeDropdown(e);}" class="swv-dropdown-list-item" role="menuitem">
							Disconnect
						</li>
					</slot>
				</ul>
			</slot>
		</slot>
	</template>

	<!-- Enable modal overrides. -->
	<template>
		<slot name="modal-overlay"></slot>
	</template>
	<template>
		<slot name="modal"></slot>
	</template>
</wallet-modal-provider>
