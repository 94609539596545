
<slot v-bind="scope">
	<button
			class="swv-button swv-button-trigger btn btn-primary text-white"
			:disabled="disabled || !wallet || connecting || connected"
			@click="onClick"
	>
		<wallet-icon v-if="wallet" :wallet="wallet"></wallet-icon>
		<p v-text="content"></p>
	</button>
</slot>
